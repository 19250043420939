import axios from 'axios';
import i18n from '../i18n';

const APP_ID = 'rGcAtvsHup6B7NpRbC5f';
const APP_CODE = 'e1lH4iNdjTlSsRK7gvZTSw';
const APP_KEY = '94YQ__CB2gl1JNU87e3ppObs4ORyac391tatShiSrJs';
const BASE_URL = `https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=${APP_KEY}`;

const hereapi = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});

export async function HEREGETCOUNTRY(country, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&maxresults=20&query=${country}&language=${lang}&resultType=country`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'country');
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETCITYBYCOUNTRY(city, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${city}&language=${lang}&country=${countryCode}&maxresults=20&resultType=city`,
      headers: {},
    });
    if (response.data.suggestions && response.data.suggestions.length > 0) {
      const filteredList = response.data.suggestions.filter(item => item.matchLevel === 'city');
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETPOSTALCODESBYCOUNTRY(postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    let limit = 20;
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&q=${postalCode}&in=countryCode:${countryCode}&types=postalCode&limit=${limit}&lang=${lang}`,
      headers: {},
    });
    if (response.data.items && response.data.items.length > 0) {
      const filteredList = response.data.items;
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETCITYBYPOSTALCODE(postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&q=${postalCode}&in=countryCode:${countryCode}&types=postalCode&lang=${lang}`,
      headers: {},
    });
    if (response.data.items && response.data.items.length > 0) {
      const filteredList = response.data.items
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETSTREETBYPOSTALCODE(street, city,  postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    let limit = 20;
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&q=${postalCode} ${city} ${street}&in=countryCode:${countryCode}&limit=${limit}&type=street&lang=${lang}`,
      headers: {},
    });
    if (response.data.items && response.data.items.length > 0) {
      const filteredList = response.data.items
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}


export async function HEREGETPOSTALCODESBYCITYLIVE(postalCode, city, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&query=${postalCode}&language=${lang}&maxresults=20&resultType=postalCode&country=${countryCode}`,
      headers: {},
    });
    if (response.data.items && response.data.items.length > 0) {
      const filteredList = response.data.items;
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}

export async function HEREGETPOSTALCODESBYCITY(city,postalCode, countryCode, lang = i18n.locale.substring(0, 2)) {
  try {
    const response = await hereapi({
      method: 'get',
      url: `${BASE_URL}&q=${postalCode} ${city}&in=countryCode:${countryCode}&lang=${lang}&postalCodeMode=cityLookup&types=city`,
      headers: {},
    });
    if (response.data.items && response.data.items.length > 0) {
      const filteredList = response.data.items;
      return { error: false, data: filteredList };
    }
    return { error: true, message: 'noData' };
  } catch (err) {
    return { error: true, message: err };
  }
}
